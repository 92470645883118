<script setup>
import { ChevronDown, Menu, User, X } from 'lucide-vue-next'

const config = useRuntimeConfig()
const route = useRoute();
const isMobileMenuOpen = ref(false)
const nav = ref(null)
const isHome = computed(() => route.meta.layout === 'home')
const openMobileMenu = () => isMobileMenuOpen.value = true;

const navigationItems = computed(() => [
  { href: '/search', label: 'Reserve' },
  { href: '/parking-packs', label: 'Season Passes' },
  { href: '/market', label: 'Specials', disabled: true },
  { href: '/owner-signup', label: 'List Your Spots' },
  { href: '/signup', label: 'Sign Up', disabled: useUser().getUser.id ? true : false },
  { href: '/login', label: 'Login', disabled: useUser().getUser.id ? true : false },
].filter((item) => !item.disabled));

const aboutUsNavigationItems = computed(() => [
  { href: '/FAQs', label: 'FAQs' },
  { href: '/news-happenings', label: 'News' },
  { href: '/contact-us', label: 'Contact Us' },
  { href: '/about', label: 'Company' },
].filter((item) => !item.disabled));

const userNavigationLinks = computed(() => [
  { href: '/dashboard', label: 'My Reservations' },
  { href: '/admin', label: 'Admin Dashboard', disabled: !useUser().getUser?.is_staff },
  { href: '/dashboard/wallet', label: 'My Wallet', disabled: useUser().getUser?.role === 'PARKER' ? false : true },
  { href: '/dashboard/settings', label: 'Settings' },
].filter((item) => !item.disabled));

const handleScroll = () => {
  if (window.scrollY > 50) {
    if (nav.value) {
      nav.value.classList.add("bg-primary")
    }
  } else {
    if (nav.value) {
      nav.value.classList.remove("bg-primary")
    }
  }
}

const setupScrollListener = () => {
  if (isHome.value) {
    window.addEventListener('scroll', handleScroll)
    handleScroll()
  } else {
    window.removeEventListener('scroll', handleScroll)
    if (nav.value) {
      nav.value.classList.remove("bg-primary")
    }
  }
}

watch(isHome, () => {
  setupScrollListener()
})

onMounted(() => {
  setupScrollListener()

  if (!isHome.value) {
    if (nav.value) {
      nav.value.classList.add("bg-primary")
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <nav class="fixed z-30 w-full top-0" :class="{'drop-shadow-lg dark:bg-black': isHome, 'bg-primary dark:bg-black': !isHome }" ref="nav">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <!-- Logo and Site Title -->
        <NuxtLink class="flex items-center" to="/">
          <NuxtImg class="h-12 w-12 mr-2" src="/images/spotsurferLogo.png" alt="Logo" />
          <div class="flex flex-col">
            <span class="text-3xl text-white font-semibold uppercase">{{ config?.public?.siteName }}</span>
            <span class="text-white text-xs tracking-widest uppercase">Reserve. Park. Play.</span>
          </div>
        </NuxtLink>
        <!-- Navigation Links (Desktop) -->
        <div class="hidden lg:block">
          <div class="flex items-center gap-6">
            <NavigationMenu>
              <NavigationMenuList class="gap-6">
                <NavigationMenuItem v-for="item in navigationItems" :key="item.href">
                  <NuxtLink :to="item.href" class="transition-colors hover:text-accent-yellow text-white text-base">
                    {{ item.label }}
                  </NuxtLink>
                </NavigationMenuItem>
              </NavigationMenuList>
            </NavigationMenu>
            <DropdownMenu>
              <DropdownMenuTrigger class="flex items-center text-white hover:text-accent-yellow">
                About Us
                <ChevronDown class="ml-1" :size="20" />
              </DropdownMenuTrigger>
              <DropdownMenuContent class="border-none p-0">
                <DropdownMenuItem class="p-0" v-for="item in aboutUsNavigationItems" :key="item.label">
                  <NuxtLink class="w-full p-2" :to="item.href">{{ item.label }}</NuxtLink>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
            <DropdownMenu>
              <DropdownMenuTrigger class="text-white">
                <span class="flex items-center" v-if="useUser().getUser?.avatar">
                  <NuxtImg class="h-8 w-8 rounded-full object-cover border-2" :src="useUser().getUser?.avatar" />
                  <ChevronDown class="ml-1" :size="18" />
                </span>
                <User v-else-if="useUser().getUser?.id" />
              </DropdownMenuTrigger>
              <DropdownMenuContent class="border-none p-0">
                <DropdownMenuItem class="p-0" v-for="item in userNavigationLinks" :key="item.label">
                  <NuxtLink class="w-full p-2" :to="item.href">{{ item.label }}</NuxtLink>
                </DropdownMenuItem>
                <DropdownMenuItem><NuxtLink class="cursor-pointer w-full" @click="useLogout">Logout</NuxtLink></DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>

        <!-- Mobile menu button -->
        <div class="lg:hidden">
          <button @click="openMobileMenu">
            <Menu :size="32" class="stroke-white hover:stroke-accent-yellow" />
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile Flyout Menu -->
    <Sheet v-model:open="isMobileMenuOpen" side="right">
      <SheetContent class="w-full md:w-1/2 bg-primary text-white shadow-lg p-0 border-none">
        <SheetClose class="absolute right-2 top-2 bg-primary text-white dark:text-black z-50 rounded-full">
          <X />
        </SheetClose>
        <SheetHeader class="sr-only">
          <SheetTitle class="text-lg font-bold">Menu</SheetTitle>
        </SheetHeader>
        <SheetDescription class="sr-only">Main application menu for Spotsurfer</SheetDescription>
        <nav class="flex flex-col mt-4">
          <NuxtLink class="flex items-center" to="/">
            <NuxtImg class="h-8 w-8 mx-2" src="/images/spotsurferLogo.png" alt="Logo" />
            <div class="flex flex-col">
              <span class="text-3xl text-white font-semibold uppercase">{{ config?.public?.siteName }}</span>
              <span class="text-white text-xs tracking-widest uppercase">Reserve. Park. Play.</span>
            </div>
          </NuxtLink>
          <SheetClose class="mt-4" asChild>
            <NuxtLink
              class="flex flex-col p-2 hover:underline transition-colors"
              v-for="item in navigationItems"
              :key="item.href"
              :to="item.href"
              @click="isMobileMenuOpen = false"
            >
              {{ item.label }}
            </NuxtLink>
            <div class="flex flex-col" v-if="useUser().getUser?.id">
              <hr />
              <NuxtLink class="cursor-pointer w-full hover:underline p-2" v-for="item in userNavigationLinks" :key="item.label" :to="item.href" @click="isMobileMenuOpen = false">
                {{ item.label }}
              </NuxtLink>
            </div>
            <div class="flex flex-col">
              <hr />
              <NuxtLink class="cursor-pointer w-full hover:underline p-2" v-for="item in aboutUsNavigationItems" :key="item.label" :to="item.href" @click="isMobileMenuOpen = false">
                {{ item.label }}
              </NuxtLink>
            </div>
            <NuxtLink class="cursor-pointer w-full hover:underline p-2" @click="useLogout" v-if="useUser().getUser?.id">Logout</NuxtLink>
          </SheetClose>
        </nav>
      </SheetContent>
    </Sheet>
  </nav>
</template>
