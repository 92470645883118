<script setup>
import ColorModePicker from '@/components/custom/ColorModePicker';
import { Facebook, Instagram, Linkedin } from 'lucide-vue-next';

const footerLinks = [
  {
    title: 'Company',
    links: [
      { name: 'About Us', href: '/about' },
      { name: 'News', href: '/news-happenings' },
    ],
  },
  {
    title: 'Support',
    links: [
      { name: 'Contact Us', href: '/contact-us' },
      { name: 'Help Center', href: '/FAQs' },
      { name: 'Account Deletion', href: '/account-deletion-request' },
      { name: 'Terms & Conditions', href: '/terms-and-conditions' },
      // { name: 'Privacy Policy', href: '/privacy-policy' },
    ],
  },
  {
    title: 'Community',
    links: [
      // { name: 'Shop', href: 'https://shop.spotsurfer.com' },
      // { name: 'Giveaways', href: 'https://giveaways.spotsurfer.com' },
      { name: 'Community Hub', href: '/community' },
    ]
  }
]
</script>

<template>
  <footer class="h-fit w-full drop-shadow-md bg-primary dark:bg-black text-white p-4 lg:p-10">
    <div class="mx-auto flex flex-col justify-between">
      <div class="w-full flex lg:flex-col flex-col-reverse items-center">
        <div class="w-full flex lg:flex-row flex-col-reverse justify-between lg:items-start lg:gap-20 gap-16 p-4">
          <div class="flex flex-col gap-6 lg:w-1/4 lg:items-start lg:justify-start justify-center">
            <img class="h-20 w-20 lg:h-[100px] lg:w-[100px]" src="/images/spotsurferLogo.png">
            <!-- Brand Icons are deprecated -->
            <div class="flex flex-row gap-4">
              <a href="https://www.linkedin.com/company/spotsurfer" target="_blank">
                <Linkedin class="stroke-1" />
              </a>
              <a href="https://www.instagram.com/spotsurferofficial/" target="_blank">
                <Instagram class="stroke-1" />
              </a>
              <a href="https://www.facebook.com/p/Spotsurfer-100088235870371/" target="_blank">
                <Facebook class="stroke-1" />
              </a>
            </div>
          </div>
          <div class="flex flex-col lg:w-2/4">
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div v-for="section in footerLinks" :key="section.title">
                <h3 class="scroll-m-20 tracking-wide text-sm pb-4 font-mediu">{{ section.title }}</h3>
                <div class="flex flex-col gap-2">
                  <a
                    class="text-sm font-extralight"
                    v-for="link in section.links"
                    :key="link.name"
                    :href="link.href"
                  >
                    {{ link.name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-center gap-6 lg:w-1/4 lg:items-end">
            <div class="flex flex-col gap-2">
              <a href="https://apps.apple.com/us/app/spotsurfer/id6639622146" target="_blank">
                <NuxtImg class="h-12" src="/images/apple_app_store.svg" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.spotsurfer.mobile" target="_blank">
                <NuxtImg class="h-12" src="/images/google_play_store.svg" />
              </a>
              <ColorModePicker />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:justify-between h-32 justify-end md:items-end">
        <span class="text-3xl uppercase tracking-widest -mb-1">Reserve. Park. Play.</span>
        <p class="text-xs font-extralight text-gray-400 mt-16 md:ml-auto">
          © 2024 - {{ new Date().getFullYear() }} Hohenco Technologies LLC. All Rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>
